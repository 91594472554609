import "./jquery";
import "bootstrap";

import "../css/main.css";

import "./custom";

// split revolution slider in another chunk
import("./rev-custom.js");

if (process.env.NODE_ENV === "development") {
  import("axe-core")
    .then((axe) => axe.default.run())
    .then((results) => {
      console.log("Accessibility violations:", results.violations);
    });
}
